import React from 'react';
import { sprinkles } from '../sprinkles.css';

const Bluebox = ({ children }: { children: React.ReactNode }) => (
	<div
		className={sprinkles({
			backgroundColor: 'primaryBackground',
			borderRadius: 'medium',
			padding: {
				mobile: 'medium',
				desktopHalf: 'large',
			},
			marginTop: {
				mobile: 'small',
				desktopHalf: 'medium',
			},
			marginBottom: {
				mobile: 'small',
				desktopHalf: 'medium',
			},
		})}
	>
		{children}
	</div>
);

export default Bluebox;

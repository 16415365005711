import { MDXProvider } from '@mdx-js/react';
import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Link as DefaultLink, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import Author from '../components/Author';
import Bluebox from '../components/Bluebox';
import { ButtonRecipe, ButtonVariants } from '../components/Button.css';
import Layout, { Section } from '../components/Layout';
import { Seo } from '../components/Seo';
import { Text, Title } from '../components/Text';
import { BlogMdx } from './__mdx.css';

const Link = ({ to, children }: { to: string; children: React.ReactNode }) => (
	<DefaultLink to={to}>{children}</DefaultLink>
);

const LinkButton = ({
	to,
	children,
	buttonProps,
}: {
	children: React.ReactNode;
	to: string;
	buttonProps?: ButtonVariants;
}) => (
	<DefaultLink className={ButtonRecipe(buttonProps)} to={to}>
		{children}
	</DefaultLink>
);

const shortcodes = { Link, Section, LinkButton, Title, Text, Bluebox };

const MdxPage = ({
	pageContext: { mdx, path },
	data: { avatars },
}: {
	data: {
		avatars: {
			edges: {
				node: {
					name: string;
					childImageSharp: { gatsbyImageData: IGatsbyImageData };
				};
			}[];
		};
	};
	pageContext: { mdx: { frontmatter: any; body: string }; path: string };
}) => {
	const { t } = useTranslation('Mdx');
	return (
		<Layout cookies={false}>
			<Section className={BlogMdx} direction="vertical" align="start" gap="medium">
				<MDXProvider components={shortcodes}>
					<MDXRenderer frontmatter={mdx.frontmatter}>{mdx.body}</MDXRenderer>
				</MDXProvider>
			</Section>
			{mdx.frontmatter.path && (
				<Section justify="between" gap="larger" align="start">
					<Section justify="between" direction="horizontal" wrap gap="medium">
						{path?.includes('blog') && (
							<DefaultLink
								to="/knowledge"
								className={ButtonRecipe({
									size: 'large',
									variant: 'primary',
									wrap: 'yes',
								})}
							>
								{t('BackButtonText')}
							</DefaultLink>
						)}
						<Text variant="secondary">
							{t('PublishedAt')}: {mdx.frontmatter.published_at}
						</Text>
					</Section>
					{mdx.frontmatter.author && (
						<Author nickName={mdx.frontmatter.author} avatars={avatars} />
					)}
				</Section>
			)}
		</Layout>
	);
};

export const Head = ({
	pageContext: { mdx, path },
}: {
	pageContext: { mdx: { frontmatter: any; body: string }; path: string };
}) => {
	const image =
		mdx.frontmatter?.featured_image?.src?.childImageSharp?.gatsbyImageData?.images
			?.fallback?.src;
	return (
		<Seo
			title={mdx.frontmatter.title}
			description={mdx.frontmatter.description}
			image={image}
			pathname={path}
		/>
	);
};

export default MdxPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		avatars: allFile(
			filter: { sourceInstanceName: { eq: "images" }, relativePath: { glob: "team/*" } }
		) {
			edges {
				node {
					relativePath
					name
					childImageSharp {
						gatsbyImageData(width: 128, height: 128, placeholder: BLURRED)
					}
				}
			}
		}
	}
`;
